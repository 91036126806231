@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;700&family=Inter:wght@400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

body{
  background-color: #fffefe;
}

.calculator{
  width: 82%;
  margin: 30px auto;
}

.header{
  background: black;
  color: white;
  font-weight: bold;
}


.column{
  width: 33.3%;
  min-width: 174px;
}

.column2{
  width: 20%;
  min-width: 200px;
}

p{
  margin-bottom: 0;
}

.label{
  font-size: 1.1rem;
}

h1 {
  font-family: 'Bai Jamjuree', sans-serif;
}

div.footer {
  margin-top: 50px;
  text-align: center;
  font-size: 0.8rem;
  color: #929292;
}

.hidden{
  visibility: hidden;
  display:none;
}

@media(max-width: 750px){
  .calculator{
    width: 95%;
  }
  .wrapper{
    justify-content: center !important;
  }
  .chart:first-child{
    height: 250px !important;
  }
  .chart:last-child{
    height: auto !important;
    width: 100%;
    margin-top: 30px !important;
  }
}


@media(max-width: 550px){
  .card{
    overflow-x: scroll;
  }
}